
import { Component, Prop, Vue } from 'vue-property-decorator';
import ClientProjectsTableWrapper from '@/components/views/ClientEdit/ClientProjectsTableWrapper.vue';

@Component({
    name: 'ClientScreenProjects',
    components: { ClientProjectsTableWrapper },
})
export default class ClientScreenProjects extends Vue {
    @Prop({ required: true }) private readonly client!: any;

    private get clientId() {
        if (!this.client || !this.client?.id) {
            return null;
        }

        return this.client.id;
    }
}
