
import { Component, Vue } from 'vue-property-decorator';
import Client from '@/models/Client';
import ClientRepository from '@/repositories/ClientRepository';
import ClientScreenInfo from '@/components/views/NewClientView/ClientScreenInfo.vue';
import ClientScreenProjects from '@/components/views/NewClientView/ClientScreenProjects.vue';
import ClientType from '@/models/ClientType';

@Component({
    name: 'ShinyCall',
    components: { ClientScreenInfo, ClientScreenProjects },
})
export default class ShinyCall extends Vue {
    private clientInfoMounted = false;

    private client: null | Client = null;

    private get clientId() {
        const routeId = this.$route.params?.clientId;

        if (routeId) {
            return routeId;
        }

        return null;
    }

    private async finishedClientDOMLoading() {
        await this.$nextTick();
        this.clientInfoMounted = true;
    }

    private async created() {
        if (!this.clientId) {
            this.$notification.error({
                message: this.$t('No client found!') as string,
                description: '',
            });
            return;
        }

        try {
            await Promise.all([Client.getSingleForClientScreen(this.clientId), ClientType.getAll()]);
            this.client = ClientRepository.getById(this.clientId);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error fetching client') as string,
                description: '',
            });
            return Promise.reject();
        }
    }
}
