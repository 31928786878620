
import { Component, Prop, Vue } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import ClientTypeRepository from '@/repositories/ClientTypeRepository';

@Component({
    name: 'ClientScreenInfo',
    components: { SidebarCard },
})
export default class ClientScreenInfo extends Vue {
    @Prop({ default: false }) private readonly client!: any;

    private get addressData() {
        if (!this.client) {
            return null;
        }

        const addressFields = [
            { title: 'Street', value: this.client?.address?.streetNameAndNumber ?? '' },
            { title: 'Building', value: this.client?.address?.building ?? '' },
            { title: 'Postal code', value: this.client?.address?.poNumber ?? '' },
            { title: 'City', value: this.client?.address?.city ?? '' },
            { title: 'Country', value: this.client?.address?.country?.name ?? '' },
        ];

        return addressFields;
    }

    private get displayCustomerType() {
        return this.$t(this.client.isCompany ? 'Company' : 'Natural person');
    }

    private get clientTypes() {
        return ClientTypeRepository.getAll();
    }

    /* tslint:disable:no-bitwise */
    private hexToRgb(hex: string): string {
        const bigint = parseInt(hex.substring(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return r + ',' + g + ',' + b;
    }
    /* tslint:enable:no-bitwise */

    private get tags() {
        if (!this.client?.clientType_ids.length) {
            return null;
        }

        return this.client?.clientType_ids.map((typeId: string) => {
            return this.clientTypes.find((type) => typeId === type.id);
        });
    }

    private getTagStyle(color: string) {
        return {
            color: color,
            border: '1px solid ' + color,
            'background-color': 'rgba(' + this.hexToRgb(color) + ', 0.125)',
        };
    }

    private mounted() {
        this.$emit('finishedDOMLoading');
    }
}
